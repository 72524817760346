import React from 'react'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import tw from 'twin.macro'

const Wrap = styled.div`
  ${tw`max-w-screen-lg mx-auto mt-20 mx-5`}
`

const ImageWrap = styled.figure`
  ${tw`mx-auto mb-12`}
`

const Image = ({ input }) => (
  <Wrap>
    <ImageWrap>
      <Img
        fluid={input.primary.image.localFile.childImageSharp.fluid}
        alt={input.primary.image.alt}
      />
    </ImageWrap>
  </Wrap>
)

export default Image

Image.propTypes = {
  input: PropTypes.object.isRequired,
}
