import React from 'react'
import { Link, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import Img from 'gatsby-image'

import { Layout, SliceZone, SEO } from '../components'

const Header = styled.div`
  ${tw`text-center`}
`

const PageTitle = styled.h1`
  ${tw`px-10 leading-tight`}
`

const Intro = styled.div`
  ${tw`text-lg max-w-screen-md mx-auto px-10 mb-16`}
`

const Wrap = styled.div`
  ${tw`max-w-2xl lg:max-w-4xl mx-auto`}
`

const FeaturedImage = styled.div`
  ${tw`mb-5 mx-5`}
`

const Button = styled.div`
  ${tw`text-center mt-20`}
  a {
    ${tw`text-center uppercase border border-black inline-block mx-auto text-sm tracking-widest px-10 py-4
    hover:bg-black hover:text-white transition-all duration-300 ease-in-out`}
  }
`

const WorkTemplate = props => {
  const { data: prismicWork } = props
  const {
    body,
    intro,
    title,
    thumbnail,
    og_image,
    meta_description,
  } = prismicWork.prismicWork.data

  return (
    <Layout>
      <SEO title="Work" image={og_image.url} description={meta_description} />
      <Wrap>
        <Header>
          <PageTitle>{title}</PageTitle>
          <Intro
            dangerouslySetInnerHTML={{
              __html: intro.html,
            }}
          />
        </Header>
        <FeaturedImage>
          <Img fluid={thumbnail.localFile.childImageSharp.fluid} alt={title} />
        </FeaturedImage>
        <SliceZone allSlices={body} />
      </Wrap>
      <Button>
        <Link to="/work/">View all work</Link>
      </Button>
    </Layout>
  )
}

export default WorkTemplate

export const workQuery = graphql`
  query GET_WORK($uid: String!) {
    prismicWork(uid: { eq: $uid }) {
      data {
        og_image {
          url
        }
        intro {
          html
        }
        title
        thumbnail {
          alt
          localFile {
            childImageSharp {
              fluid(maxWidth: 900, maxHeight: 600, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        body {
          ... on PrismicWorkBodyContent {
            id
            slice_type
            primary {
              content {
                html
              }
            }
          }
          ... on PrismicWorkBodyImage {
            id
            slice_type
            primary {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 900, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

WorkTemplate.propTypes = {
  data: PropTypes.shape({
    prismicWork: PropTypes.object.isRequired,
    posts: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }),
  }).isRequired,
}

// https://github.com/LekoArts/gatsby-starter-prismic/blob/master/src/templates/post.jsx
