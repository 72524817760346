import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import tw from 'twin.macro'

const Content = styled.div`
  ${tw`px-10 max-w-screen-md mx-auto`}
  h2 {
    ${tw`text-lg md:text-2xl mt-8 mb-5`}
  }
  h3 {
    ${tw`text-xl mt-8`}
  }
  p {
    font-size: 0.85rem;
    line-height: 1.75;
  }
  a {
    ${tw`underline`}
  }
  ol,
  ul {
    ${tw`list-inside`}
    li {
      font-size: 0.85rem;
      line-height: 1.75;
      ${tw`mb-2`}
    }
  }
  ul {
    ${tw`list-disc`}
  }
  ol {
    ${tw`list-decimal`}
  }
`

const BodyContent = ({ input }) => (
  <Content dangerouslySetInnerHTML={{ __html: input.primary.content.html }} />
)

export default BodyContent

BodyContent.propTypes = {
  input: PropTypes.object.isRequired,
}
